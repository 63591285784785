

















































































@import '~@/assets/styles/helpers/_variables.scss';

.toolBar {
  background-color: $colorPrimary !important;
  border-radius: 16px !important;
  display: flex;
  justify-content: center;
  margin: 0px;
  color: white;

  &-text {
    padding: 5px 0;
    font-weight: bold;
    text-transform: uppercase;
    @media(max-width:400px) {
      font-size: 16px;
    }
  }
}

.utilButton {
  color: $colorPrimaryDarken;
  border: 3px solid $colorPrimaryDarken;
  border-radius: 16px;


  &:hover {
    color: white;
    border: 3px solid white;
  }
}
